import React, { useState, useLayoutEffect } from "react";
import { GameFragment, PageNew } from "../../../fragments";
import './index.scss';


const Game = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useLayoutEffect(() => {
    setIsLoaded(true);
  }, [setIsLoaded]);

  return (
    <PageNew
      title="Game"
      description="Game"
      headline="Game"
    >
      <div className="_form_3"></div>
      <div className="form-below">After you press the submit button, please check your email for game instructions.</div>
      {isLoaded ? <GameFragment /> : null}
    </PageNew>
  );
}

export default Game